import CheckInIconWhite from '@/components/icons/CheckInIconWhite.vue';
import GivingIconWhite from '@/components/icons/GivingIconWhite.vue';
import HaloIconWhite from '@/components/icons/HaloIconWhite.vue';
import LaunchIconWhite from '@/components/icons/LaunchIconWhite.vue';
import CheckInLogoWhite from '@/components/logos/CheckInLogoWhite.vue';
import GivingLogoColor from '@/components/logos/GivingLogoColor.vue';
import GivingLogoWhite from '@/components/logos/GivingLogoWhite.vue';
import HaloLogoWhite from '@/components/logos/HaloLogoWhite.vue';
import LaunchLogoColor from '@/components/logos/LaunchLogoColor.vue';
import LaunchLogoWhite from '@/components/logos/LaunchLogoWhite.vue';
import { userState } from '@/composables/useUser';
import {
    Contact,
    ContactPhone,
    ContentBlock, DonorItem,
    DonorOrganization,
    LaunchProject,
    Organization,
    Person,
    Tenant,
    User
} from '@/graphql/operations';
import { flashStore } from '@/store';
import { DraggableItem, LaunchPhase, LaunchTopic } from '@/types';
import { MOBILE_SCREEN_WIDTH, MODULES } from '@/utils/constants';
import { cloneDeep, flatten, floor, inRange, isArray, lowerCase, max, min, trim } from 'lodash';
import { DateTime } from 'luxon';
import { Component, ComputedRef } from 'vue';
import i18n from '../i18n';

const { t } = i18n.global;

const getDonorName = (
    donor: Person | DonorOrganization | DonorItem,
    fullName = true
): string => {
    if (!donor) {
        return '';
    }

    if (donor.prefixedId.includes('don_org_')) {
        return (donor as DonorOrganization).name;
    }

    if (fullName && donor.prefixedId.includes('pers_')) {
        return `${donor?.firstName ?? ''} ${
            donor?.lastName ?? ''
        }`;
    }

    return (donor as Person)?.firstName ?? '';
};

const getPersonName = (person: Person, fullName = true): string => {
    if (fullName) {
        return `${person?.firstName ?? ''} ${person?.lastName ?? ''}`;
    }
    return person.firstName ?? '';
};

const includeTag = (
    tagName: string,
    attributes: Record<string, string>
): void => {
    const scriptElement = document.createElement(tagName);

    Object.keys(attributes).forEach((attributeName) => {
        const attributeValue = attributes[attributeName];

        scriptElement.setAttribute(attributeName, attributeValue);
    });

    scriptElement.onload = function () {
        const DOMContentLoadedEvent = document.createEvent('Event');
        DOMContentLoadedEvent.initEvent('DOMContentLoaded', true, true);
        window.document.dispatchEvent(DOMContentLoadedEvent);
    };

    document.getElementsByTagName('head')[0].appendChild(scriptElement);
};

const includeScript = (url: string): void => {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', url);
    document.head.appendChild(scriptElement);
};

const formatAddress = (contact: Contact | DonorItem | null, htmlOutput?: boolean): string => {
    if (contact) {
        if (htmlOutput) {
            return `${contact.address ? `${contact.address}<br/>` : ''}${
                contact.city ? `${contact.city}, ` : ''
            }${contact.state || ''} ${contact.zip || ''}`;
        }

        return `${contact.address ? `${contact.address}\n` : ''}${
            contact.city ? `${contact.city}, ` : ''
        }${contact.state || ''} ${contact.zip || ''}`;
    }

    return '';
};

const formatPhones = (
    contact: Contact | null,
    includePhoneType = false
): string => {
    const contactPhones =
        contact?.phones?.filter((cp) => {
            return cp?.phone;
        }) ?? [];

    if (includePhoneType) {
        return (
            contactPhones
                .filter((contactPhone): contactPhone is ContactPhone => {
                    return !!contactPhone;
                })
                .map((contactPhone) => {
                    return contactPhone ?
                        `${contactPhone.phone}${
                            contactPhone.type ?
                                `<span class="ml-1 text-xs sm:text-sm text-gray-600 italic" role="note">${contactPhone?.type}</span>` :
                                ``
                        }` :
                        '';
                })
                .join('<br/>') ?? ''
        );
    }

    return (
        contactPhones
            .map((contactPhone) => {
                return contactPhone?.phone;
            })
            .join(', ') ?? ''
    );
};

const formatPhone = (phone: string | null) => {
    if (!phone) {
        return '';
    }

    // Remove all non-digit characters
    const cleanPhone = phone.replace(/[^\d]/g, '');

    // Define formatting patterns based on the length
    const formattingPatterns: Record<number, string> = {
        7: 'xxx-xxxx',
        10: 'xxx-xxx-xxxx',
        11: 'x-xxx-xxx-xxxx',
        12: '+xx xxx xxx xxxx'
    };

    // Check if the length matches a formatting pattern
    if (formattingPatterns[cleanPhone.length]) {
        const pattern = formattingPatterns[cleanPhone.length];
        let formattedPhone = '';
        let index = 0;

        // Replace pattern placeholders with digits from the cleaned phone number
        for (let i = 0; i < pattern.length; i++) {
            if (pattern[i] === 'x') {
                formattedPhone += cleanPhone[index];
                index += 1;
            } else {
                formattedPhone += pattern[i];
            }
        }

        return formattedPhone;
    }

    // If the length doesn't match any pattern, return the cleaned phone number as is
    return cleanPhone;
};

const formatStrings = (stringArray: string[]) => {
    return trim(stringArray.join(' '));
};

const moduleIcons: { [index: string]: Component } = {};
moduleIcons[MODULES.GIVING] = GivingIconWhite;
moduleIcons[MODULES.LAUNCH] = LaunchIconWhite;
moduleIcons[MODULES.CHECK_IN] = CheckInIconWhite;

const moduleIconsSolid: { [index: string]: Component } = {};
moduleIconsSolid[MODULES.GIVING] = GivingIconWhite;
moduleIconsSolid[MODULES.LAUNCH] = LaunchIconWhite;
moduleIconsSolid[MODULES.CHECK_IN] = CheckInIconWhite;
moduleIconsSolid[MODULES.BUNDLE] = HaloIconWhite;

const moduleIconsColor: { [index: string]: Component } = {};
moduleIconsColor[MODULES.GIVING] = GivingLogoColor;
moduleIconsColor[MODULES.LAUNCH] = LaunchLogoColor;

const moduleLogos: { [index: string]: Component } = {};
moduleLogos[MODULES.GIVING] = GivingLogoWhite;
moduleLogos[MODULES.LAUNCH] = LaunchLogoWhite;
moduleLogos[MODULES.CHECK_IN] = CheckInLogoWhite;
moduleLogos[MODULES.BUNDLE] = HaloLogoWhite;

const getOrganizationsFromTenant = (tenant: Tenant) => {
    const orgs = [];

    if (tenant?.primaryOrganization) {
        orgs.push(tenant.primaryOrganization);
    }

    if (tenant?.secondaryOrganizations) {
        orgs.push(...tenant.secondaryOrganizations);
    }

    return orgs;
};

const getUserOrganizations = (user: User, tenantPrefixedId?: string) => {
    const tenants = tenantPrefixedId ?
        user.tenants?.filter((ten) => {
            return ten.prefixedId === tenantPrefixedId;
        }) :
        user.tenants;

    return flatten(
        tenants?.map((tenant) => {
            return getOrganizationsFromTenant(tenant);
        })
    );
};

const hasValidActiveOrganization = (
    activeOrganization: Organization | null,
    user: User
): boolean => {
    if (!activeOrganization) {
        return false;
    }

    const organization = getUserOrganizations(
        user,
        user.tenant?.prefixedId
    ).find((org) => {
        return (
            org.prefixedId === activeOrganization.prefixedId &&
            org.availableModules?.length
        );
    });

    return organization !== null && organization !== undefined;
};

const generateUuid = (): string => {
    let result;
    let i;
    let j;
    result = '';
    for (j = 0; j < 32; j++) {
        if (j === 8 || j === 12 || j === 16 || j === 20) result += '-';
        i = Math.floor(Math.random() * 16)
            .toString(16)
            .toUpperCase();
        result += i;
    }

    return result;
};

const abbreviateFrequency = (frequency: string) => {
    switch (lowerCase(frequency)) {
        case 'month':
        case 'monthly':
            return t('cart.frequency.month.abbreviation');
        case 'year':
        case 'yearly':
            return t('cart.frequency.year.abbreviation');
        default:
            return frequency;
    }
};

const reorderItems = (
    dragged: DraggableItem,
    dropped: DraggableItem,
    allItems: ComputedRef<DraggableItem[]>
) => {
    return allItems.value.map((item) => {
        // Move dragged item to dropped position
        if (item.prefixedId === dragged.prefixedId) {
            return { ...item, order: dropped.order };
        }

        // Move dropped item either up or down
        if (item.prefixedId === dropped.prefixedId) {
            return {
                ...item,
                order:
                    dragged.order > dropped.order ?
                        item.order + 1 :
                        item.order - 1
            };
        }

        // Move items in between either up or down
        if (inRange(item.order, dragged.order, dropped.order)) {
            return {
                ...item,
                order:
                    dragged.order > dropped.order ?
                        item.order + 1 :
                        item.order - 1
            };
        }

        // Leave items outside the dropped/dragged items in their current position
        return item;
    });
};

const randomIntFromInterval = (minimum: number, maximum: number): number => {
    return Math.floor(Math.random() * (maximum - minimum + 1) + minimum);
};

const calculateLaunchTopicPercentComplete = (
    topic: LaunchTopic,
    checkApplicable = false
) => {
    let total = 0;
    let checked = 0;

    const tasks = checkApplicable ?
        topic?.launchTasks.filter((task) => {
            return !task.isNotApplicable;
        }) :
        topic?.launchTasks;

    tasks.map((task) => {
        total += 1;
        if (task?.isChecked) {
            checked += 1;
        }
    });

    return Math.round((checked / total) * 100);
};

const calculateLaunchPhasePercentComplete = (
    phase: LaunchPhase,
    checkApplicable = false
) => {
    let total = 0;
    let checked = 0;

    phase?.launchTopics?.map((topic) => {
        const tasks = checkApplicable ?
            topic?.launchTasks.filter((task) => {
                return !task.isNotApplicable;
            }) :
            topic?.launchTasks;

        tasks.map((task) => {
            total += 1;
            if (task?.isChecked) {
                checked += 1;
            }
        });
    });

    return Math.round((checked / total) * 100);
};

const sortTopicsAndTasks = (phase: LaunchPhase) => {
    return phase.launchTopics
        .map((topic: LaunchTopic) => {
            // Sort the tasks by order and filter out tasks which are not applicable
            const sortedTasks = topic.launchTasks
                .sort((a, b) => {
                    return a.order - b.order;
                })
                .filter((task) => {
                    return !task.isNotApplicable;
                });

            // Check the topic checkbox if every related task is checked
            const isChecked = topic.launchTasks.every((task) => {
                return task.isChecked;
            });

            return {
                ...topic,
                launchTasks: sortedTasks,
                _isChecked: isChecked
            };
        })
        .sort((a, b) => {
            // We sort the topics by order
            return a.order - b.order;
        });
};

const getPhaseStartDate = (
    phase: LaunchPhase,
    launchProject: LaunchProject
) => {
    const projectClone = cloneDeep(launchProject);

    return phase.order <= 1 ?
        launchProject.startDate :
        DateTime.fromISO(
            projectClone.launchPhases.sort((a, b) => {
                return b.order - a.order;
            }).find((p) => {
                return p.order < phase.order;
            })?.dueDate
        )
            .plus({ day: 1 })
            .toFormat('y-LL-dd');
};

const parseContentBlocks = (contentBlocks: ContentBlock[]) => {
    const filteredBlocks = contentBlocks.filter((block) => {
        return block.valid;
    });

    return {
        primary: filteredBlocks.at(0) as ContentBlock,
        secondary: filteredBlocks.filter((block, index) => {
            return index !== 0;
        }),
        imageUrl: filteredBlocks.at(0)?.featuredImage?.url as string
    };
};

const setLimits = (value: number, upperLimit = 100, lowerLimit = 0) => {
    return max([min([value, upperLimit]), lowerLimit]) as number;
};

const getGanttTaskCssClass = (start: string, end: string, progress: number): string => {
    const classPrefix = 'gantt-task-';

    const plannedTime = DateTime.fromISO(end).diff(DateTime.fromISO(start)).as('days');
    const passedTime = DateTime.local().diff(DateTime.fromISO(start)).as('days');
    const expectedProgress = floor(setLimits((passedTime / plannedTime) * 100, 100, 0));

    let status;

    if (expectedProgress === 100 && progress !== 100) {
        status = 'overdue';
    } else if (expectedProgress === 0 || progress >= expectedProgress) {
        status = 'regular';
    } else if (progress / expectedProgress > 0.5) {
        status = 'regular';
    } else {
        status = 'getting-overdue';
    }

    return classPrefix + status;
};

const delay = (ms: number) => {
    return new Promise((resolve) => {
        return setTimeout(resolve, ms);
    });
};

const hasActiveSoftwareForUser = (tenant: Tenant) => {
    const orgs = getOrganizationsFromTenant(tenant);
    const user = userState.getUser.value;

    // If the user belongs to the "admin team" and the tenant has some available modules, return true
    if (
        user.rolesTeams?.find((team) => {
            return (
                team.tenantId === tenant.id &&
                !team.organizationId &&
                orgs.some((org) => {
                    return org.availableModules?.length;
                })
            );
        })
    ) {
        return true;

        // If the user belongs to an org team that has available modules, return true
    } else if (
        orgs.find((org) => {
            return (
                org.availableModules?.length &&
                user.rolesTeams?.find((team) => {
                    return team.organizationId === org.id;
                })
            );
        })
    ) {
        return true;
    }
    return false;
};

const stringToInt = (s: string | number): number => {
    if (typeof s === 'string') {
        return Number(s.replace(/\D/g, ''));
    } else {
        return s;
    }
};

const validateAddComma = (s: string): string => {
    return s.replace(/\D/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const getRandomItem = (items: string[]) => {
    return items[Math.floor(Math.random() * items.length)];
};

const isMobileView = (): boolean => {
    return window.innerWidth < MOBILE_SCREEN_WIDTH;
};

const removeUrlAppend = (url: string, separator = '?') => {
    const parts = url.split(separator);

    if (isArray(parts)) {
        return parts[0];
    }

    return url;
};

const getBaseUrl = (): string => {
    return import.meta.env.VITE_BASE_URL ?? '';
};

const validateUrlPattern = (url: string) => {
    const regex = /^(https?:\/\/)?(?:([^./]+)\.)?([^.]+\.[^.]+)/;
    return url.match(regex);
};

const getOnlineGivingUrl = (slug: string, previewCode?: string): string => {
    let url = getBaseUrl();
    const result = validateUrlPattern(url);

    if (result) {
        const protocol = result[1] || '';
        const domain = result[3];

        url = `${protocol}${slug}.${domain}`;
    }

    if (previewCode) {
        url += `/${previewCode}`;
    }

    return url;
};

const openUrlInNewTab = (url: string): void => {
    window?.open(url, '_blank')?.focus();
};

const truncateString = (value: string, limit: number) => {
    if (value.length > limit) {
        return value.slice(0, limit).trimEnd() + '...';
    }
    return value;
};

const getExtensionFromString = (s: string): string => {
    const parts = s.match(/(?:\.([^.]+))?$/);
    return parts?.length ? parts[0] : '';
};

const pickProperties = (obj: any, keys: any) => {
    return Object.keys(obj).filter(k => {
        return keys.includes(k);
    }).reduce((res, k) => {
        return Object.assign(res, { [k]: obj[k] });
    }, {});
};

const getPeopleByFamilyRole = (people: Person[], role: string | string[]) => {
    if (Array.isArray(role)) {
        return people.filter((person) => {
            return role.some((r) => {
                return person.pivot?.role === r;
            });
        });
    }

    return people.filter((person) => {
        return person.pivot?.role === role;
    });
};

const getUrlParam = (param: string): string | null => {
    return new URLSearchParams(window.location.search).get(param);
};

const setFocusOnFirstInput = () => {
    const elements = document.getElementsByTagName('input');
    if (elements.length) {
        elements[0].focus();
    }
};

const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const calculateDateRange = (range: string) => {
    const currentDate = DateTime.local();
    const dateFormat = 'y-LL-dd';
    let start;
    const end = currentDate.toFormat(dateFormat);

    switch (range) {
        case '30 days': {
            start = currentDate.minus({ days: 30 });
            break;
        }
        case '12 months': {
            start = currentDate.minus({ months: 11 }).startOf('month');
            break;
        }
        default: {
            start = currentDate.minus({ days: 6 });
            break;
        }
    }

    start = start.toFormat(dateFormat);

    return { start, end };
};

const copyToClipboard = (text: string, message = t('flash.copied')) => {
    navigator.clipboard.writeText(text).then(() => {
        return flashStore.doFlash({ message: message });
    });
};

const setCookie = (name: string, value: string, days = 7) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    let cookieValue = encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString() + '; path=/';

    const hostname = window.location.hostname;

    if (hostname) {
        // Regular expression to extract the domain from the hostname
        const domainMatch = hostname.match(/(?:\.([^.]+)){1,2}\.([^.]+)$/);

        // If a match is found, set the extracted domain as the cookie domain; otherwise, use the full hostname as the domain
        const domain = domainMatch ? domainMatch[0] : hostname;

        cookieValue += '; domain=' + domain;
    }

    document.cookie = name + '=' + cookieValue;
};

const scrollToElement = (elementId: string, position: ScrollLogicalPosition = 'start') => {
    document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth', block: position });
};

// function to verify if localStorage is available
const isLocalStorageAvailable = (): boolean => {
    try {
        if (typeof window.localStorage === 'undefined') return false;

        const test = 'test';
        localStorage.setItem(test, test);
        if (localStorage.getItem(test) !== test) return false;
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
};

const downloadData = (data: string, fileName: string): void => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = data;
    link.click();
};

const getMaxStartAndEndDates = (startDate?: string | null, endDate?: string | null, maxStartDateAsCurrent = true) => {
    const defaultMaxStartDate = maxStartDateAsCurrent ? DateTime.now().toISODate() : undefined;
    const maxStartDate = (endDate ? DateTime.fromISO(endDate).toISODate() : defaultMaxStartDate) ?? undefined;
    const minEndDate = startDate ? (DateTime.fromISO(startDate).toISODate() ?? undefined) : undefined;
    const maxEndDate = DateTime.now().toISODate();

    return { maxStartDate, minEndDate, maxEndDate };
};

export {
    getDonorName,
    includeScript,
    includeTag,
    formatAddress,
    moduleIcons,
    moduleIconsSolid,
    moduleLogos,
    hasValidActiveOrganization,
    getUserOrganizations,
    formatPhones,
    formatPhone,
    generateUuid,
    formatStrings,
    abbreviateFrequency,
    reorderItems,
    randomIntFromInterval,
    calculateLaunchPhasePercentComplete,
    sortTopicsAndTasks,
    getPhaseStartDate,
    calculateLaunchTopicPercentComplete,
    parseContentBlocks,
    getGanttTaskCssClass,
    delay,
    hasActiveSoftwareForUser,
    getOrganizationsFromTenant,
    stringToInt,
    validateAddComma,
    getRandomItem,
    isMobileView,
    removeUrlAppend,
    getBaseUrl,
    getOnlineGivingUrl,
    openUrlInNewTab,
    truncateString,
    moduleIconsColor,
    getExtensionFromString,
    getPersonName,
    pickProperties,
    getPeopleByFamilyRole,
    getUrlParam,
    setFocusOnFirstInput,
    setLimits,
    capitalizeFirstLetter,
    calculateDateRange,
    copyToClipboard,
    setCookie,
    scrollToElement,
    isLocalStorageAvailable,
    downloadData,
    getMaxStartAndEndDates,
    validateUrlPattern
};
