import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { userState } from '@/composables/useUser';
import i18n from '../i18n';

const { t } = i18n.global;

const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: 'dashboard',
        name: 'admin.dashboard',
        redirect: { name: 'organizations.index' }
    },
    {
        path: 'organizations',
        name: 'organizations',
        redirect: { name: 'organizations.index' },
        components: {
            navigationLinks: (): Component => {
                return import('../components/navigation/AdminLinks.vue');
            },
            default: (): Component => {
                return import('../views/organizations/Organization.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: { title: t('navigation.adminDashboard') },
        children: [
            {
                path: '',
                name: 'organizations.index',
                component: (): Component => {
                    return import('../views/organizations/OrganizationIndex.vue');
                },
                props: () => {
                    return { tenantPrefixedId: userState.getUser.value.tenant?.prefixedId };
                }
            },
            {
                path: 'create',
                name: 'organizations.create',
                component: (): Component => {
                    return import('../views/organizations/OrganizationCreate.vue');
                },
                props: () => {
                    return { tenantPrefixedId: userState.getUser.value.tenant?.prefixedId };
                },
                meta: { can: ['create:organization'] }
            },
            {
                path: 'organization-profile',
                name: 'organization.profile',
                redirect: { name: 'organization.profile.index' },
                component: (): Component => {
                    return import('../views/organizations/organizationProfile/OrganizationProfile.vue');
                },
                props: (route) => {
                    return { organizationPrefixedId: route.params.organizationPrefixedId };
                },
                children: [
                    {
                        path: ':organizationPrefixedId/profile',
                        name: 'organization.profile.index',
                        component: (): Component => {
                            return import('../views/organizations/organizationProfile/OrganizationProfileView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':organizationPrefixedId/edit',
                        name: 'organization.profile.edit',
                        component: (): Component => {
                            return import('../views/organizations/organizationProfile/OrganizationEdit.vue');
                        },
                        props: (route) => {
                            return {
                                tenantPrefixedId: userState.getUser.value.tenant?.prefixedId,
                                organizationPrefixedId: route.params.organizationPrefixedId
                            };
                        }
                    },
                    {
                        path: ':organizationPrefixedId/billing',
                        name: 'organization.profile.billing',
                        component: (): Component => {
                            return import('../views/billing/BillingIndex.vue');
                        },
                        meta: { skipBillingCheck: true },
                        props: (route) => {
                            return {
                                tenantPrefixedId: userState.getUser.value.tenant?.prefixedId,
                                organizationPrefixedId: route.params.organizationPrefixedId
                            };
                        }
                    },
                    {
                        path: ':organizationPrefixedId/manage-software',
                        name: 'organization.profile.manage.software',
                        component: (): Component => {
                            return import('../views/billing/SoftwareSelection.vue');
                        },
                        meta: { skipBillingCheck: true },
                        props: (route) => {
                            return {
                                tenantPrefixedId: userState.getUser.value.tenant?.prefixedId,
                                organizationPrefixedId: route.params.organizationPrefixedId
                            };
                        }
                    },
                    {
                        path: ':organizationPrefixedId/notifications',
                        name: 'organization.profile.notifications',
                        component: (): Component => {
                            return import('../views/notifications/NotificationsIndex.vue');
                        },
                        props: (route) => {
                            return {
                                tenantPrefixedId: userState.getUser.value.tenant?.prefixedId,
                                organizationPrefixedId: route.params.organizationPrefixedId
                            };
                        }
                    }
                ]
            }
        ]
    },
    {
        path: 'users',
        components: {
            navigationLinks: (): Component => {
                return import('../components/navigation/AdminLinks.vue');
            },
            default: (): Component => {
                return import('../views/users/User.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: { title: 'Admin Dashboard' },
        children: [
            {
                path: '',
                name: 'users.index',
                component: (): Component => {
                    return import('../views/users/UserIndex.vue');
                },
                meta: { description: 'Users' }
            },
            {
                path: 'admin-profile',
                name: 'admin.profile',
                redirect: { name: 'admin.profile.index' },
                component: (): Component => {
                    return import('../views/Profile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':personPrefixedId?',
                        name: 'admin.profile.index',
                        component: (): Component => {
                            return import('../views/profile/ProfileView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/edit',
                        name: 'admin.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/ProfileEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/update-contact',
                        name: 'admin.profile.updateContact',
                        component: (): Component => {
                            return import('../views/profile/ContactUpdate.vue');
                        },
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'admin.profile.add',
                        component: (): Component => {
                            return import('../views/profile/UserAdd.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/contact',
                        name: 'admin.profile.contact',
                        component: (): Component => {
                            return import('../views/profile/ContactView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/giving',
                        name: 'admin.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/ProfileGiving.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/admin',
                        name: 'admin.profile.permissions',
                        component: (): Component => {
                            return import('../views/profile/ProfilePermissions.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/notifications',
                        name: 'admin.profile.notifications',
                        component: (): Component => {
                            return import('../views/profile/ProfileNotifications.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/recurring-giving',
                        name: 'admin.profile.recurringGiving',
                        component: (): Component => {
                            return import('../views/profile/ProfileRecurringGiving.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'admin-donor-org-profile',
                name: 'admin.donorOrganization.profile',
                redirect: { name: 'admin.donorOrganization.profile.index' },
                component: (): Component => {
                    return import('../views/DonorOrgProfile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':donorOrganizationPrefixedId?',
                        name: 'admin.donorOrganization.profile.index',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileIndex.vue');
                        },
                        props: true
                    },
                    {
                        path: ':donorOrganizationPrefixedId?/edit',
                        name: 'admin.donorOrganization.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'admin.donorOrganization.profile.add',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileAdd.vue');
                        }
                    },
                    {
                        path: ':donorOrganizationPrefixedId?/giving',
                        name: 'admin.donorOrganization.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileGiving.vue');
                        },
                        props: true
                    },
                    {
                        path: ':donorOrganizationPrefixedId?/recurringGiving',
                        name: 'admin.donorOrganization.profile.recurringGiving',
                        component: (): Component => {
                            return import('../views/profile/ProfileRecurringGiving.vue');
                        },
                        props: true
                    }
                ]
            }
        ]
    }
];

export default adminRoutes;
