import { computed } from 'vue';
import i18n from '../i18n';

const { t } = i18n.global;

const MODULES = {
    GIVING: 'giving',
    LAUNCH: 'launch',
    CHECK_IN: 'check-in',
    BUNDLE: 'bundle'
};

const GROUP_TYPES = {
    CHECKIN_CLASS: 'checkin_class',
    CHECKIN_VOLUNTEERS: 'checkin_volunteers'
};

const EVENT_TEMPLATE_TYPES = { CHECKIN: 'checkin' };

const LAUNCH_TEAMS = computed(() => {
    return [
        { key: 'isBoardMember', value: t('launch.people.boardMember') },
        { key: 'isContributor', value: t('launch.people.contributor') },
        { key: 'isAttendee', value: t('launch.people.attendee') },
        { key: 'isTeamLeader', value: t('launch.people.teamLeader') },
        { key: 'isVolunteer', value: t('launch.people.volunteer') }
    ];
});

const MOBILE_SCREEN_WIDTH = 414;
const DESKTOP_SCREEN_WIDTH = 1024;

const BROADCASTING_CHANNELS = {
    ORGANIZATION: 'App.Models.Organization',
    TENANT: 'App.Models.Tenant'
};

const BROADCASTING_EVENTS = {
    LAUNCH_PROJECT_UPDATED: '.LaunchProjectUpdated',
    TENANT_ORGANIZATIONS_UPDATED: '.TenantOrganizationsUpdated',
    KDS_MIGRATION_COMPLETED: '.KdsMigrationCompleted'
};

const SIDEBAR_COLORS = {
    AQUA: 'aqua',
    PERIWINKLE: 'periwinkle'
};

const CHECKBOX_COLORS = {
    GOLD: 'gold',
    SUCCESS: 'success'
};

const TUTORIALS = {
    HALO: 'halo',
    GIVING: 'giving',
    ADMIN: 'admin',
    UPDATE0523: 'update0523'
};

const URL_HELPERS = {
    GIVING: 'https://www.startchurch.com/churchhalo/givinghalo',
    LAUNCH: 'https://www.startchurch.com/churchhalo/launchhalo'
};

const CHAR_KEYCODE_COMMA = 44;

const IMAGE_FILE_SIZES = {
    '1MB': {
        bytes: 1048576,
        errorMessage: t('forms.validation.imageSize', { size: '1MB' })
    },
    '500KB': {
        bytes: 524288,
        errorMessage: t('forms.validation.imageSize', { size: '500KB' })
    }
};

const S3_COMMANDS = {
    HEAD: 'HeadObject',
    PUT: 'PutObject',
    DELETE: 'DeleteObject'
};

const REGEX = {
    ALL_EXCEPT_DASHES_AND_ALPHANUMERIC_CHARACTERS: /[^a-zA-Z0-9-]/g,
    ALL_EXCEPT_DASHES_UNDERSCORES_AND_ALPHANUMERIC_CHARACTERS: /[^a-zA-Z0-9-_]/g,
    ALL_EXCEPT_DASHES_SPACES_AND_ALPHANUMERIC_CHARACTERS: /[^a-zA-Z0-9-\s]/g,
    ALL_EXCEPT_DASHES_UNDERSCORES_SPACES_AND_ALPHANUMERIC_CHARACTERS: /[^a-zA-Z0-9-_ ]/g,
    DASHES_AT_BEGINNING_AND_END: /^-+|-+$/g,
    MULTIPLE_DASHES: /-+/g,
    WHITESPACE: /\s/g
};

const STRIPE_STATUS = {
    ACTIVE: 'active',
    CANCELED: 'canceled',
    INCOMPLETE: 'incomplete',
    INCOMPLETE_EXPIRED: 'incomplete_expired',
    PAST_DUE: 'past_due',
    TRIALING: 'trialing',
    UNPAID: 'unpaid'
};

// KDS to Halo connect GET parameter name
const KDS_CONNECT_KEY_PARAMETER = 'uuid';

// KDS to Halo local storage name
const KDS_CONNECT_KEY_LOCAL_STORAGE = 'cloudKey';

const FILE_TYPES = {
    CSV: 'csv',
    PDF: 'pdf'
};

const SHOW_GIVING_TUTORIAL_KEY_SESSION_STORAGE = 'showGivingTutorialWidget';

const DATA_TABLE_PAGE_LENGTH_OPTIONS = ['20', '50', '100', '250', 'All'];

const LABEL_TEMPLATES = {
    AVERY_5160: 'avery-5160',
    AVERY_94272: 'avery-94272'
};

const CONTRIBUTION_REPORT_ACTIONS = {
    DOWNLOAD: 'Download',
    PREVIEW: 'Preview'
} as const;

const CONTRIBUTION_REPORT_MODES = {
    STATEMENT: 'Statement',
    RECEIPT: 'Receipt',
    DOWNLOAD :'Download'
} as const;

const enum REPORT_PRESETS {
    DONORS_BY_TOTAL = 'donors-by-total',
    LABELS = 'labels',
    FORM_990 = 'form-990'
}

const enum REPORT_TYPES {
    CONTRIBUTIONS = 'contributions',
    DONORS = 'donors'
}

const MAXIMUM_DONORS_TO_PREVIEW_REPORT = 20;

const DATATABLE_INFINITE_SCROLL_CHUNK_SIZE = 500;

const HELP_URL = 'https://startchurch.atlassian.net/wiki/spaces/PS/pages/173735937/Customizing+Your+Donation+Page+URL';

const enum CONTRIBUTION_TYPES {
    ONE_TIME_UNPOSTED = 'one-time-unposted',
    ONE_TIME_POSTED = 'one-time-posted',
    ONE_TIME_ALL = 'one-time-all',
    RECURRING = 'recurring'
}

export {
    MODULES,
    LAUNCH_TEAMS,
    MOBILE_SCREEN_WIDTH,
    BROADCASTING_CHANNELS,
    BROADCASTING_EVENTS,
    SIDEBAR_COLORS,
    CHECKBOX_COLORS,
    TUTORIALS,
    URL_HELPERS,
    CHAR_KEYCODE_COMMA,
    IMAGE_FILE_SIZES,
    S3_COMMANDS,
    REGEX,
    STRIPE_STATUS,
    KDS_CONNECT_KEY_PARAMETER,
    KDS_CONNECT_KEY_LOCAL_STORAGE,
    GROUP_TYPES,
    EVENT_TEMPLATE_TYPES,
    FILE_TYPES,
    DESKTOP_SCREEN_WIDTH,
    SHOW_GIVING_TUTORIAL_KEY_SESSION_STORAGE,
    DATA_TABLE_PAGE_LENGTH_OPTIONS,
    LABEL_TEMPLATES,
    REPORT_PRESETS,
    REPORT_TYPES,
    CONTRIBUTION_REPORT_ACTIONS,
    CONTRIBUTION_REPORT_MODES,
    MAXIMUM_DONORS_TO_PREVIEW_REPORT,
    DATATABLE_INFINITE_SCROLL_CHUNK_SIZE,
    HELP_URL,
    CONTRIBUTION_TYPES
};
