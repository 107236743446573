import { getOnlineGivingUrl } from '@/utils/helper';
import { defineComponent } from 'vue';
import { RouteRecordRaw } from 'vue-router';

const RedirectComponent = defineComponent({
    beforeRouteEnter (to) {
        const slug = to.params.slug as string;
        window.location.href = getOnlineGivingUrl(slug);
    },
    render () {
        return null;
    }
});

const guestRoutes: Array<RouteRecordRaw> = [
    {
        path: '/give/:slug/:previewCode?',
        name: 'give',
        component: RedirectComponent,
        meta: { guest: true },
        props: true
    }
];

export default guestRoutes;
