import { MODULES } from '@/utils/constants';
import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import i18n from '../i18n';

const { t } = i18n.global;

const givingRoutes: Array<RouteRecordRaw> = [
    {
        path: 'giving',
        name: 'giving',
        redirect: { name: 'giving.dashboard' },
        components: {
            default: (): Component => {
                return import('../views/giving/Giving.vue');
            },
            navigationLinks: (): Component => {
                return import('../components/navigation/GivingLinks.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: {
            can: 'view:giving-module',
            requiredModule: MODULES.GIVING,
            title: t('modules.giving'),
            icon: `halo_giving_favicon`
        },
        children: [
            {
                path: 'dashboard',
                name: 'giving.dashboard',
                component: (): Component => {
                    return import('../views/giving/dashboard/GivingDashboard.vue');
                }
            },
            {
                path: 'people',
                component: (): Component => {
                    return import('../views/People.vue');
                },
                children: [
                    {
                        path: '',
                        name: 'giving.people.index',
                        component: (): Component => {
                            return import('../views/giving/donors/DonorIndex.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'donor-organizations',
                component: (): Component => {
                    return import('../views/giving/donorOrganizations/DonorOrganizations.vue');
                },
                props: true,
                children: [
                    {
                        path: '',
                        name: 'giving.donorOrganizations.index',
                        component: (): Component => {
                            return import('../views/giving/donorOrganizations/DonorOrganizationIndex.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'contributions',
                component: () => import('../views/giving/contributions/Contributions.vue'),
                props: true,
                children: [
                    {
                        path: '',
                        name: 'giving.contributions.index',
                        redirect: { name: 'giving.contributions.index.unposted' },
                        component: () => import('../views/giving/contributions/ContributionIndex.vue'),
                        children: [
                            {
                                path: 'unposted',
                                name: 'giving.contributions.index.unposted',
                                component: () => import('../views/giving/contributions/index/ContributionTab.vue'),
                                props: { title: t('giving.contributions.unposted') }
                            },
                            {
                                path: 'posted',
                                name: 'giving.contributions.index.posted',
                                component: () => import('../views/giving/contributions/index/ContributionTab.vue'),
                                props: { title: t('giving.contributions.posted') }
                            },
                            {
                                path: 'all',
                                name: 'giving.contributions.index.all',
                                component: () => import('../views/giving/contributions/index/ContributionTab.vue'),
                                props: { title: t('giving.contributions.all') }
                            },
                            {
                                path: 'recurring',
                                name: 'giving.contributions.index.recurring',
                                component: () => import('../views/giving/recurringGifts/RecurringGiftsPage.vue'),
                                props: { title: t('giving.contributions.futureGifts') }
                            }
                        ]
                    },
                    {
                        path: ':donorPrefixedId?/create',
                        name: 'giving.contributions.create',
                        component: (): Component => {
                            return import('../views/giving/contributions/ContributionCreate.vue');
                        },
                        props: true
                    },
                    {
                        path: ':contributionPrefixedId/edit',
                        name: 'giving.contributions.edit',
                        component: (): Component => {
                            return import('../views/giving/contributions/ContributionEdit.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'reports',
                name: 'giving.reports.index',
                component: (): Component => {
                    return import('../views/giving/reports/Reports.vue');
                },
                props: (route) => { return { filters: route.query }; }
            },
            {
                path: 'reports',
                component: (): Component => {
                    return import('../views/giving/reports/ReportsLayout.vue');
                },
                // meta: {
                //     can: [ 'view-any:reports' ]
                // },
                children: [
                    {
                        path: '',
                        name: 'giving.reports',
                        redirect: { name: 'giving.reports.index' },
                        component: (): Component => {
                            return import('../views/giving/reports/Reports.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.reports.index',
                                component: (): Component => {
                                    return import('../views/giving/reports/ReportsIndex.vue');
                                },
                                props: true
                            },
                            {
                                path: 'labels',
                                name: 'giving.reports.labels',
                                component: (): Component => {
                                    return import('../views/giving/reports/ReportsLabels.vue');
                                }
                            },
                            {
                                path: 'donors-by-total',
                                name: 'giving.reports.donorsByTotal',
                                component: (): Component => {
                                    return import('../views/giving/reports/ReportsDonorsByTotal.vue');
                                },
                                props: true
                            },
                            {
                                path: 'form-990',
                                name: 'giving.reports.form990',
                                component: (): Component => {
                                    return import('../views/giving/reports/ReportsForm990.vue');
                                },
                                props: true
                            },
                            {
                                path: 'custom-reports',
                                name: 'giving.reports.customReports',
                                component: (): Component => {
                                    return import('../views/giving/reports/ReportsCustomReports.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'types',
                        name: 'giving.attendance.types',
                        redirect: { name: 'giving.attendance.types.index' },
                        component: (): Component => {
                            return import('../views/giving/attendance/types/AttendanceTypes.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.attendance.types.index',
                                component: (): Component => {
                                    return import('../views/giving/attendance/types/AttendanceTypeIndex.vue');
                                }
                            },
                            {
                                path: 'create',
                                name: 'giving.attendance.types.create',
                                component: (): Component => {
                                    return import('../views/giving/attendance/types/AttendanceTypeCreate.vue');
                                }
                            },
                            {
                                path: ':attendanceTypePrefixedId/edit',
                                name: 'giving.attendance.types.edit',
                                component: (): Component => {
                                    return import('../views/giving/attendance/types/AttendanceTypeEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'locations',
                        name: 'giving.attendance.locations',
                        redirect: { name: 'giving.attendance.locations.index' },
                        component: (): Component => {
                            return import('../views/giving/attendance/locations/AttendanceLocations.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.attendance.locations.index',
                                component: (): Component => {
                                    return import('../views/giving/attendance/locations/AttendanceLocationIndex.vue');
                                }
                            },
                            {
                                path: 'create',
                                name: 'giving.attendance.locations.create',
                                component: (): Component => {
                                    return import('../views/giving/attendance/locations/AttendanceLocationCreate.vue');
                                }
                            },
                            {
                                path: ':attendanceLocationPrefixedId/edit',
                                name: 'giving.attendance.locations.edit',
                                component: (): Component => {
                                    return import('../views/giving/attendance/locations/AttendanceLocationEdit.vue');
                                },
                                props: true
                            }
                        ]
                    }
                ]
            },
            {
                path: 'deposits',
                component: (): Component => {
                    return import('../views/giving/deposits/Deposits.vue');
                },
                children: [
                    {
                        path: '',
                        name: 'giving.deposits.index',
                        component: (): Component => {
                            return import('../views/giving/deposits/DepositIndex.vue');
                        }
                    },
                    {
                        path: ':depositPrefixedId/edit',
                        name: 'giving.deposits.edit',
                        component: (): Component => {
                            return import('../views/giving/deposits/DepositEdit.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'attendance',
                component: (): Component => {
                    return import('../views/giving/attendance/AttendanceLayout.vue');
                },
                meta: { can: ['view-any:attendance'] },
                children: [
                    {
                        path: '',
                        name: 'giving.attendance',
                        redirect: { name: 'giving.attendance.index' },
                        component: (): Component => {
                            return import('../views/giving/attendance/Attendance.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.attendance.index',
                                component: (): Component => {
                                    return import('../views/giving/attendance/AttendanceIndex.vue');
                                },
                                props: true
                            },
                            {
                                path: 'create',
                                name: 'giving.attendance.create',
                                component: (): Component => {
                                    return import('../views/giving/attendance/AttendanceCreate.vue');
                                }
                            },
                            {
                                path: ':attendancePrefixedId/edit',
                                name: 'giving.attendance.edit',
                                component: (): Component => {
                                    return import('../views/giving/attendance/AttendanceEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'types',
                        name: 'giving.attendance.types',
                        redirect: { name: 'giving.attendance.types.index' },
                        component: (): Component => {
                            return import('../views/giving/attendance/types/AttendanceTypes.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.attendance.types.index',
                                component: (): Component => {
                                    return import('../views/giving/attendance/types/AttendanceTypeIndex.vue');
                                }
                            },
                            {
                                path: 'create',
                                name: 'giving.attendance.types.create',
                                component: (): Component => {
                                    return import('../views/giving/attendance/types/AttendanceTypeCreate.vue');
                                }
                            },
                            {
                                path: ':attendanceTypePrefixedId/edit',
                                name: 'giving.attendance.types.edit',
                                component: (): Component => {
                                    return import('../views/giving/attendance/types/AttendanceTypeEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'locations',
                        name: 'giving.attendance.locations',
                        redirect: { name: 'giving.attendance.locations.index' },
                        component: (): Component => {
                            return import('../views/giving/attendance/locations/AttendanceLocations.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.attendance.locations.index',
                                component: (): Component => {
                                    return import('../views/giving/attendance/locations/AttendanceLocationIndex.vue');
                                }
                            },
                            {
                                path: 'create',
                                name: 'giving.attendance.locations.create',
                                component: (): Component => {
                                    return import('../views/giving/attendance/locations/AttendanceLocationCreate.vue');
                                }
                            },
                            {
                                path: ':attendanceLocationPrefixedId/edit',
                                name: 'giving.attendance.locations.edit',
                                component: (): Component => {
                                    return import('../views/giving/attendance/locations/AttendanceLocationEdit.vue');
                                },
                                props: true
                            }
                        ]
                    }
                ]
            },
            {
                path: 'settings',
                name: 'giving.settings',
                redirect: { name: 'giving.depositAccounts.index' },
                component: (): Component => {
                    return import('../views/giving/settings/GivingSettings.vue');
                },
                children: [
                    {
                        path: 'deposit-accounts',
                        component: (): Component => {
                            return import('../views/giving/settings/depositAccounts/DepositAccount.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.depositAccounts.index',
                                component: (): Component => {
                                    return import('../views/giving/settings/depositAccounts/DepositAccountIndex.vue');
                                }
                            },
                            {
                                path: 'create',
                                name: 'giving.depositAccounts.create',
                                component: (): Component => {
                                    return import('../views/giving/settings/depositAccounts/DepositAccountCreate.vue');
                                }
                            },
                            {
                                path: ':depositAccountId/edit',
                                name: 'giving.depositAccounts.edit',
                                component: (): Component => {
                                    return import('../views/giving/settings/depositAccounts/DepositAccountEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'fund-accounts',
                        component: (): Component => {
                            return import('../views/giving/settings/fundAccounts/FundAccount.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.fundAccounts.index',
                                component: (): Component => {
                                    return import('../views/giving/settings/fundAccounts/FundAccountIndex.vue');
                                }
                            },
                            {
                                path: 'create',
                                name: 'giving.fundAccounts.create',
                                component: (): Component => {
                                    return import('../views/giving/settings/fundAccounts/FundAccountCreate.vue');
                                }
                            },
                            {
                                path: ':fundAccountId/edit',
                                name: 'giving.fundAccounts.edit',
                                component: (): Component => {
                                    return import('../views/giving/settings/fundAccounts/FundAccountEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'letter-templates',
                        component: (): Component => {
                            return import('../views/giving/settings/letterTemplates/LetterTemplate.vue');
                        },
                        children: [
                            {
                                path: '',
                                component: (): Component => {
                                    return import('../views/giving/settings/letterTemplates/LetterTemplateIndex.vue');
                                },
                                name: 'giving.letterTemplates.index'
                            },
                            {
                                path: 'create',
                                name: 'giving.letterTemplates.create',
                                component: (): Component => {
                                    return import('../views/giving/settings/letterTemplates/LetterTemplateCreate.vue');
                                }
                            },
                            {
                                path: ':letterTemplateId/edit',
                                name: 'giving.letterTemplates.edit',
                                component: (): Component => {
                                    return import('../views/giving/settings/letterTemplates/LetterTemplateEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'online-giving',
                        component: (): Component => {
                            return import('../views/giving/settings/onlineGiving/OnlineGiving.vue');
                        },
                        children: [
                            {
                                path: '',
                                component: (): Component => {
                                    return import('../views/giving/integrations/Integration.vue');
                                },
                                children: [
                                    {
                                        path: '',
                                        component: (): Component => {
                                            return import('../views/giving/integrations/IntegrationIndex.vue');
                                        },
                                        name: 'giving.onlineGiving.index',
                                        props: true
                                    }
                                ]
                            },
                            {
                                path: 'page-builder',
                                name: 'giving.onlineGiving.pageBuilder',
                                component: (): Component => {
                                    return import('../views/giving/settings/onlineGiving/OnlineGivingPageBuilder.vue');
                                }
                            }
                        ]
                    },
                    {
                        path: 'migration',
                        name: 'giving.kdsMigration',
                        component: (): Component => {
                            return import('../views/giving/settings/kdsMigration/KdsMigration.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'giving.kdsMigration.index',
                                component: (): Component => {
                                    return import('../views/giving/settings/kdsMigration/KdsMigrationIndex.vue');
                                }
                            }
                        ]
                    }
                ]
            },

            {
                path: 'giving-profile',
                name: 'giving.profile',
                redirect: { name: 'giving.profile.contact' },
                component: (): Component => {
                    return import('../views/Profile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':personPrefixedId?',
                        name: 'giving.profile.index',
                        component: (): Component => {
                            return import('../views/profile/ProfileView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/edit',
                        name: 'giving.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/ProfileEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/update-contact',
                        name: 'giving.profile.updateContact',
                        component: (): Component => {
                            return import('../views/profile/ContactUpdate.vue');
                        },
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'giving.profile.add',
                        component: (): Component => {
                            return import('../views/profile/ContactAdd.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/launch',
                        name: 'giving.profile.launch',
                        component: (): Component => {
                            return import('../views/profile/ProfileLaunch.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/contact',
                        name: 'giving.profile.contact',
                        component: (): Component => {
                            return import('../views/profile/ContactView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/giving',
                        name: 'giving.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/ProfileGiving.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/admin',
                        name: 'giving.profile.permissions',
                        component: (): Component => {
                            return import('../views/profile/ProfilePermissions.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/families',
                        name: 'giving.profile.families',
                        component: (): Component => {
                            return import('../views/profile/ProfileFamilies.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/notifications',
                        name: 'giving.profile.notifications',
                        component: (): Component => {
                            return import('../views/profile/ProfileNotifications.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/recurringGiving',
                        name: 'giving.profile.recurringGiving',
                        component: (): Component => {
                            return import('../views/profile/ProfileRecurringGiving.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'giving-donor-org-profile',
                name: 'giving.donorOrganization.profile',
                redirect: { name: 'giving.donorOrganization.profile.index' },
                component: (): Component => {
                    return import('../views/DonorOrgProfile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':donorOrganizationPrefixedId?',
                        name: 'giving.donorOrganization.profile.index',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileIndex.vue');
                        },
                        props: true
                    },
                    {
                        path: ':donorOrganizationPrefixedId?/edit',
                        name: 'giving.donorOrganization.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'giving.donorOrganization.profile.add',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileAdd.vue');
                        }
                    },
                    {
                        path: ':donorOrganizationPrefixedId?/giving',
                        name: 'giving.donorOrganization.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/DonorOrgProfileGiving.vue');
                        },
                        props: true
                    },
                    {
                        path: ':donorOrganizationPrefixedId?/recurringGiving',
                        name: 'giving.donorOrganization.profile.recurringGiving',
                        component: (): Component => {
                            return import('../views/profile/ProfileRecurringGiving.vue');
                        },
                        props: true
                    }
                ]
            }
        ]
    }
];

export default givingRoutes;
