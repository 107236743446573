import trans from '@/utils/translation';
import { createI18n } from 'vue-i18n';
import en_general from './lang/en/general.json';
import en_giving from './lang/en/giving.json';
import en_launch from './lang/en/launch.json';
import en_home from './lang/en/home.json';
import es_general from './lang/es/general.json';
import es_giving from './lang/es/giving.json';
import es_launch from './lang/es/launch.json';
import es_home from './lang/es/home.json';

const messages = {
    en: { ...en_general, ...en_giving, ...en_launch, ...en_home },
    es: { ...es_general, ...es_giving, ...es_launch, ...es_home }
};

const i18n = createI18n({
    locale: trans.guessDefaultLocale(),
    fallbackLocale: 'en',
    messages,
    legacy: false,
    globalInjection: true
});

export default i18n;
